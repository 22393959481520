import React, { ReactElement, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import Button from '@objects/button'
import TextField from '@objects/formfields/textField'
import CheckboxField from '@objects/formfields/checkboxField'
import RichTextEditor from '@objects/formfields/richtext'
import Headline from '@objects/headline'
import Select from '@objects/formfields/select'
import clsx from 'clsx'
import FontSize from '@config/theme/definitions/fontSize'
import feedbackformSaveData from '@api/feedbackformSaveData'

import { navigate } from 'gatsby'
import { ToggleComponentContext } from '@providers/toggleComponentProvider'
import useFeedback from '@hooks/useFeedback'
import useDragAndDrop from '@hooks/useDragAndDrop'
import { FeedbackFormValues, FilterOptionType } from '@/types/feedback'
import { regions } from '@utils/getRegions'
import { FriendlyCaptcha } from '@objects/formfields'
import { useMediaQuery } from '@material-ui/core'
import ScrollToFormikFieldError from '@tools/ScrollToFormikFieldError'

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(10.5),
      padding: theme.spacing(2, 6),
    },
    margin: theme.spacing(7, 0, 8, 0),
    display: 'inherit',
    '&:focus': {
      border: '2px dotted currentColor',
    },
  },
  send: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  title: {
    width: '100%',
    textAlign: 'left',
  },
  headline: {
    paddingBottom: theme.spacing(3.875),
    borderBottom: '2px solid rgb(191, 209, 7)',
  },
  required: {
    marginTop: theme.spacing(4),
    textAlign: 'right',
  },
  text: {
    '& p': {
      marginBottom: 0,
      fontSize: FontSize['xs'],
    },
  },
  captcha: {
    '& > div': {
      marginTop: 0,
    },
  },
  warning: {
    fontSize: FontSize['xs'],
    color: theme.palette.error.dark,
  },
}))

export type FeedbackFormProps = {
  terms: React.ReactElement
  contact: React.ReactElement
}

export default function FeedbackForm({
  terms,
  contact,
}: FeedbackFormProps): ReactElement {
  const theme = useTheme()
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const { setFormData } = useFeedback()
  const { setFriendlyCaptcha, friendlyCaptcha } = useFeedback()
  const { filePaths, uploadedImages } = useDragAndDrop()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const validationSchema = Yup.object({
    topicOfAction: Yup.string().required(
      intl.formatMessage({ id: 'events.topic.error.required' })
    ),
    company: Yup.string(),
    date: Yup.string().required(
      intl.formatMessage({ id: 'events.date.error.required' })
    ),
    duration: Yup.string().matches(
      /^[0-9]+(\,[0-9]+)?$/,
      intl.formatMessage({ id: 'events.digits.error.required' })
    ),
    venue: Yup.string().required(
      intl.formatMessage({ id: 'events.venue.error.required' })
    ),
    place: Yup.string().required(
      intl.formatMessage({ id: 'events.place.error.required' })
    ),
    occasion: Yup.string().required(
      intl.formatMessage({ id: 'events.occasion.error.required' })
    ),
    bigEvent: Yup.bool(),
    smallEvent: Yup.bool(),
    visitors: Yup.string()
      .required(intl.formatMessage({ id: 'events.field.error.required' }))
      .matches(
        /^[0-9-]+$/,
        intl.formatMessage({ id: 'events.digits.error.required' })
      ),
    materials: Yup.string().required(
      intl.formatMessage({ id: 'events.field.error.required' })
    ),
    materialsAmount: Yup.string().required(
      intl.formatMessage({ id: 'events.field.error.required' })
    ),
    missingMaterials: Yup.string(),
    ambassadors: Yup.string(),
    organisation: Yup.string().required(
      intl.formatMessage({ id: 'events.field.error.required' })
    ),
    wishes: Yup.string(),
    drugs: Yup.string(),
    alcohol: Yup.string(),
    satisfied: Yup.string(),
    technicalDefects: Yup.string(),
    regions: Yup.string().required(
      intl.formatMessage({ id: 'events.field.error.required' })
    ),
    lastname: Yup.string().required(
      intl.formatMessage({ id: 'checkout.lastname.error.required' })
    ),
    firstname: Yup.string().required(
      intl.formatMessage({ id: 'checkout.firstname.error.required' })
    ),
    ministry: Yup.string().required(
      intl.formatMessage({ id: 'events.ministry.error.required' })
    ),
    address: Yup.string().required(
      intl.formatMessage({ id: 'checkout.address.error.required' })
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'checkout.email.error' }))
      .required(intl.formatMessage({ id: 'checkout.email.error.required' })),
    phone: Yup.string()
      .required(intl.formatMessage({ id: 'events.phone.error.required' }))
      .matches(
        /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/,
        intl.formatMessage({ id: 'events.digits.error.required' })
      ),
    termsOfPrivacy: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: 'checkout.termsOfPrivacy.error.required' })
    ),
  })

  const initialValues: FeedbackFormValues = {
    address: '',
    ambassadors: '',
    bigEvent: false,
    company: '',
    date: '',
    duration: '',
    email: '',
    firstname: '',
    lastname: '',
    materials: '',
    visitors: '',
    materialsAmount: '',
    ministry: '',
    missingMaterials: '',
    organisation: '',
    wishes: '',
    drugs: '',
    alcohol: '',
    satisfied: '',
    technicalDefects: '',
    occasion: '',
    phone: '',
    venue: '',
    place: '',
    regions: '',
    smallEvent: false,
    termsOfPrivacy: false,
    topicOfAction: '',
    friendlyCaptchaCode: '',
  }

  const selectOptions: FilterOptionType[] = regions.map((opt) => ({
    label: opt.name,
    value: opt.id,
  }))

  function _handleSubmit(
    values: FeedbackFormValues,
    { resetForm, setSubmitting }: FormikHelpers<FeedbackFormValues>
  ): void {
    const finalValues = { ...values }
    const data = {
      data: JSON.stringify(finalValues),
      files: filePaths,
      friendlyCaptchaCode: friendlyCaptcha,
    }
    feedbackformSaveData(data)
      .then((response: Response) => {
        setSubmitting(true)
        if (response.status === 202) {
          setFormData([finalValues])
          resetForm()
          navigate(
            intl.formatMessage({
              id: 'navigation.breadcrumb.events.thanks.url',
            })
          )
        } else {
          toggleOverlay(true, {
            type: 'feedbackError',
            trackingID: 'feedbackError',
            width: 'sm',
            onAcceptance: () => toggleOverlay(false),
          })
        }
        setSubmitting(false)
      })
      .catch(() => {
        toggleOverlay(true, {
          type: 'feedbackError',
          trackingID: 'feedbackError',
          width: 'sm',
          onAcceptance: () => toggleOverlay(false),
        })
        setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting, touched, values, errors, initialValues }) => {
        const handleOnChangeSelect = (
          selectedOption: FilterOptionType | null,
          name: string
        ): void => {
          if (selectedOption) {
            values[name] = selectedOption.label
          }
        }
        if (values.friendlyCaptchaCode) {
          setFriendlyCaptcha(values.friendlyCaptchaCode)
        }
        return (
          <Form id="feedback">
            <ScrollToFormikFieldError />
            <Grid
              container
              spacing={5}
              justifyContent="center"
              style={{ paddingBottom: theme.spacing(32.5) }}
            >
              <Grid item xs={12} className={classes.required}>
                <FormattedMessage id={'events.form.action.required'} />
              </Grid>
              <Grid item xs={12}>
                <Headline
                  level={3}
                  className={clsx(classes.headline, classes.title)}
                >
                  <FormattedMessage id={'events.form.action.title'} />
                </Headline>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="topicOfAction.0"
                  name="topicOfAction"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.form.topic.label',
                  })}
                  label=""
                  value={values.topicOfAction}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="date.0"
                  name="date"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.form.date.label',
                  })}
                  label=""
                  value={values.date}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="duration.0"
                  name="duration"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.form.duration.label',
                  })}
                  label=""
                  value={values.duration}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="venue.0"
                  name="venue"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.form.venue.label',
                  })}
                  label=""
                  value={values.venue}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  settings={{
                    id: 'place.0',
                    name: 'place',
                    defaultValue: null,
                    value: selectOptions?.find((o) => o.value === values.place),
                    onChange: (selectedOption) =>
                      handleOnChangeSelect(selectedOption, 'place'),
                    options: selectOptions,
                    autoFocus: false,
                    placeholder: intl.formatMessage({
                      id: `events.form.place.label`,
                    }),
                  }}
                />
                {touched.place && initialValues.place === '' && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: '#FF3000',
                      fontSize: '14px',
                      fontWeight: '700',
                    }}
                  >
                    {errors.place}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="occasion.0"
                  name="occasion"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.form.occasion.label',
                  })}
                  label=""
                  value={values.occasion}
                />
              </Grid>
              {!values.smallEvent && (
                <Grid item xs={12}>
                  <CheckboxField
                    name="bigEvent"
                    checked={values.bigEvent}
                    labelId="events.form.bigevent.checkbox"
                  />
                </Grid>
              )}
              {!values.bigEvent && (
                <Grid item xs={12}>
                  <CheckboxField
                    name="smallEvent"
                    checked={values.smallEvent}
                    labelId="events.form.smallevent.checkbox"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  id="visitors.0"
                  name="visitors"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.form.visitors.label',
                  })}
                  label=""
                  value={values.visitors}
                />
              </Grid>
              <Grid item xs={12} id="materials.0">
                <RichTextEditor
                  id="materials"
                  name="materials"
                  maxLength={750}
                  label={intl.formatMessage({
                    id: `events.form.materials.label`,
                  })}
                  showInfoText
                />
              </Grid>
              <Grid item xs={12} id="materialsAmount.0">
                <RichTextEditor
                  id="materialsAmount"
                  name="materialsAmount"
                  maxLength={750}
                  label={intl.formatMessage({
                    id: `events.form.materials.amount.label`,
                  })}
                  showInfoText
                />
              </Grid>
              <Grid item xs={12} id="missingMaterials.0">
                <RichTextEditor
                  id="missingMaterials"
                  name="missingMaterials"
                  maxLength={750}
                  label={intl.formatMessage({
                    id: `events.form.missing.materials.label`,
                  })}
                  showInfoText
                />
              </Grid>
              {values.bigEvent && (
                <Grid item xs={12} id="ambassadors.0">
                  <RichTextEditor
                    id="ambassadors.0"
                    name="ambassadors"
                    maxLength={750}
                    label={intl.formatMessage({
                      id: `events.form.ambassadors.label`,
                    })}
                    showInfoText
                  />
                </Grid>
              )}
              <Grid item xs={12} id="organisation.0">
                <RichTextEditor
                  id="organisation"
                  name="organisation"
                  maxLength={750}
                  label={intl.formatMessage({
                    id: `events.form.organisation.label`,
                  })}
                  showInfoText
                  isLongLabel
                />
              </Grid>
              <Grid item xs={12} id="wishes.0">
                <RichTextEditor
                  id="wishes"
                  name="wishes"
                  maxLength={750}
                  label={intl.formatMessage({
                    id: `events.form.wishes.label`,
                  })}
                  showInfoText
                  isLongLabel
                />
              </Grid>
              <Grid item xs={12} id="drugs.0">
                <RichTextEditor
                  id="drugs"
                  name="drugs"
                  maxLength={750}
                  label={intl.formatMessage({
                    id: `events.form.drugs.label`,
                  })}
                  showInfoText
                  isLongLabel
                />
              </Grid>
              <Grid item xs={12} id="alcohol.0">
                <RichTextEditor
                  id="alcohol"
                  name="alcohol"
                  maxLength={750}
                  label={intl.formatMessage({
                    id: `events.form.alcohol.label`,
                  })}
                  showInfoText
                  isLongLabel
                />
              </Grid>
              <Grid item xs={12} id="satisfied.0">
                <RichTextEditor
                  id="satisfied"
                  name="satisfied"
                  maxLength={750}
                  label={intl.formatMessage({
                    id: `events.form.satisfied.label`,
                  })}
                  showInfoText
                  isLongLabel
                />
              </Grid>
              <Grid item xs={12} id="technicalDefects.0">
                <RichTextEditor
                  id="technicalDefects"
                  name="technicalDefects"
                  maxLength={750}
                  label={intl.formatMessage({
                    id: `events.form.technicalDefects.label`,
                  })}
                  showInfoText
                  isLongLabel
                />
              </Grid>
              <Grid item xs={12}>
                <Headline
                  level={3}
                  className={clsx(classes.headline, classes.title)}
                >
                  <FormattedMessage
                    id={'events.form.sender.information.title'}
                  />
                </Headline>
              </Grid>
              <Grid item xs={12}>
                <Select
                  settings={{
                    id: 'regions.0',
                    name: 'regions',
                    defaultValue: null,
                    onChange: (selectedOption) =>
                      handleOnChangeSelect(selectedOption, 'regions'),
                    options: selectOptions,
                    value: selectOptions?.find(
                      (o) => o.value === values.regions
                    ),
                    autoFocus: false,
                    placeholder: intl.formatMessage({
                      id: `events.form.regions.label`,
                    }),
                  }}
                />
                {touched.regions && initialValues.regions === '' && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: '#FF3000',
                      fontSize: '14px',
                      fontWeight: '700',
                    }}
                  >
                    {errors.regions}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="lastname.0"
                  name="lastname"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.lastname.label',
                  })}
                  label=""
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="firstname.0"
                  name="firstname"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.firstname.label',
                  })}
                  label=""
                  autoComplete="given-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ministry.0"
                  name="ministry"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.form.ministry.authority.label',
                  })}
                  label=""
                  autoComplete="given-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address.0"
                  name="address"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.address.label',
                  })}
                  label=""
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email.0"
                  name="email"
                  type="email"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.email.label',
                  })}
                  label=""
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="phone.0"
                  name="phone"
                  type="text"
                  variant="outlined"
                  customVariant={'form'}
                  placeholder={intl.formatMessage({
                    id: 'events.phone.label',
                  })}
                  label=""
                  autoComplete="tel"
                />
              </Grid>
              <Grid item xs={12} className={classes.text} id="termsOfPrivacy.0">
                <CheckboxField name="termsOfPrivacy" label={terms} />
              </Grid>
              <Grid item xs={12} className={classes.text}>
                {contact}
              </Grid>
              {!isLarge && (
                <Grid item className={classes.captcha}>
                  <FriendlyCaptcha name="friendlyCaptchaCode" />
                </Grid>
              )}
              <Grid item xs={12} className={classes.send}>
                {isLarge && (
                  <Grid item className={classes.captcha}>
                    <FriendlyCaptcha name="friendlyCaptchaCode" />
                  </Grid>
                )}
                <Button
                  className={classes.button}
                  disabled={
                    isSubmitting ||
                    !values.friendlyCaptchaCode ||
                    uploadedImages.length > 0
                  }
                  formSubmit
                >
                  {intl.formatMessage({ id: 'events.form.button.send' })}
                </Button>
              </Grid>
              {uploadedImages.length > 0 && (
                <Grid item xs={12} className={classes.warning}>
                  <FormattedMessage id={'events.form.photo.warning'} />
                </Grid>
              )}
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
