const axios = require('axios')

export default async function feedbackformAddFile(
  values: string | File
): Promise<Response> {
  const formData = new FormData()
  formData.append('File', values)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'image/*',
    },
  }
  const response = await axios.post(
    process.env.GATSBY_CONTACT_SERVICE_URL + '/api/feedback/add-file',
    formData,
    config
  )
  return response
}
