import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@objects/container'
import { FormattedMessage, useIntl } from 'react-intl'
import get from 'lodash/get'

import BreadCrumbs from '@objects/breadcrumb'
import FeedbackForm from '@components/forms/feedbackform'
import Copy from '@components/copy'
import { graphql } from 'gatsby'
import Headline from '@objects/headline'
import DragAndDrop from '@objects/dragAndDrop'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { DragAndDropProvider } from '@providers/dragAndDropProvider'

const useStyles = makeStyles((theme) => ({
  headlineWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
  },
  headline: {
    marginBottom: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(7, 0, 8, 0),
    display: 'inherit',
  },
}))

export type FeedbackProps = {
  data: {
    subline: {
      copy: {
        raw: string
      }
    }
    terms: {
      copy: {
        raw: string
      }
    }
    contact: {
      copy: {
        raw: string
      }
    }
  }
}

export default function RootIndex({ data }: FeedbackProps): ReactElement {
  const subline = get(data, 'subline.copy')
  const terms = <Copy richtext={get(data, 'terms.copy')} />
  const contact = <Copy richtext={get(data, 'contact.copy')} />

  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <DragAndDropProvider>
      <div>
        <Container background={'light'} type="nomargin">
          <BreadCrumbs
            crumbList={[
              {
                label: intl.formatMessage({
                  id: 'navigation.breadcrumb.events.feedback.label',
                }),
                link: intl.formatMessage({
                  id: 'navigation.breadcrumb.events.feedback.url',
                }),
              },
            ]}
          />
        </Container>
        <Container className={classes.headlineWrapper} width={'sm'}>
          <Headline className={classes.headline} level={1}>
            <FormattedMessage id={'events.form.title'} />
          </Headline>
          <Copy richtext={subline} />
          <Headline className={classes.headline} level={isMobile ? 3 : 1}>
            <FormattedMessage id={'events.form.photos.title'} />
          </Headline>
          <DragAndDrop />
        </Container>
        <Container width={'sm'} background={'light'}>
          <FeedbackForm terms={terms} contact={contact} />
        </Container>
      </div>
    </DragAndDropProvider>
  )
}

export const pageQuery = graphql`
  query {
    subline: contentfulModuleText(
      id: { eq: "c7de44dc-6ee1-5fac-bebd-2d57dd11a00d" }
    ) {
      copy {
        raw
      }
    }
    terms: contentfulModuleText(
      id: { eq: "c34dd90e-a173-564a-a4ff-9d542b492363" }
    ) {
      copy {
        raw
      }
    }
    contact: contentfulModuleText(
      id: { eq: "b78260a7-e7bb-556a-a579-3160d42ed81b" }
    ) {
      copy {
        raw
      }
    }
  }
`
