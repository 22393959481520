import React, { useState } from 'react'
import {
  CircularProgress,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Icon from '@objects/icon'
import useDragAndDrop from '@hooks/useDragAndDrop'
import FontSize from '@config/theme/definitions/fontSize'
import { FormattedMessage } from 'react-intl'
import { Cancel } from '@material-ui/icons'
import Button from '../button'

const useStyles = makeStyles((theme) => ({
  uploadDeafult: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(18.25, 2.5),
    border: '1px solid #979797',
    borderRadius: '5px',
    '&:hover': { background: '#EEE' },
  },
  uploadGrid: {
    position: 'relative',
    display: 'grid',
    width: '100%',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(18.25, 2.5),
    border: '1px solid #979797',
    borderRadius: '5px',
    '&:hover': { background: '#EEE' },
  },
  uploadHidden: {
    display: 'none',
  },
  uploadText: { padding: '0 50px' },
  uploadTextDefault: {
    fontWeight: 'bold',
    borderBottom: '2px solid #bfd107',
    cursor: 'pointer',
    width: '100%',
  },
  uploadTextTop: {
    position: 'absolute',
    width: 'max-content',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  imageGrid: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    padding: '5px',
    width: 'fit-content',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'red',
    background: 'white',
    borderRadius: '50%',
    transform: 'translateX(25%)',
    [theme.breakpoints.up('lg')]: {
      cursor: 'pointer',
    },
  },
  sendButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: '5px',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      fontSize: '12px',
    },
  },
  uploadedImage: { width: '100px' },
  headerIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '100%',
    color: theme.palette.primary.main,
    justifyContent: 'end',
    marginBottom: theme.spacing(8),
    '& svg': {
      height: '40px',
      width: '40px',
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerInfo: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    marginBottom: 0,
    fontSize: FontSize['xs'],
    opacity: '.4',
    marginLeft: theme.spacing(1.25),
  },
}))

const DragAndDrop: () => JSX.Element = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const {
    handleFileUpload,
    setUploadedImages,
    uploadedImages,
    isLoaded,
    isLoading,
  } = useDragAndDrop()
  const [isDragging, setIsDragging] = useState(false)

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'copy'
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDrop = (
    type: string,
    event:
      | React.DragEvent<HTMLDivElement>
      | HTMLInputElement
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (type === 'drop') {
      event.preventDefault()
    }
    handleFileUpload(type, event)
    setIsDragging(false)
  }

  const handleDeleteImage = (index: number) => {
    const newImages = uploadedImages
      .slice(0, index)
      .concat(uploadedImages.slice(index + 1))
    setUploadedImages(newImages)
  }

  const sendImage = (type: string, inputElement: HTMLInputElement) => {
    handleFileUpload(type, inputElement)
  }

  return (
    <Grid
      item
      xs={12}
      className={
        uploadedImages.length > 0 ? classes.uploadGrid : classes.uploadDeafult
      }
      style={{
        background: isDragging ? '#EEE' : '#FFF',
        gridTemplateColumns: `repeat(auto-fill, ${
          isMobile ? '100px' : '200px'
        }`,
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={(e) => handleDrop('drop', e)}
    >
      <input
        type="file"
        id="upload"
        multiple
        accept="image/*"
        className={classes.uploadHidden}
        onChange={(event) => handleFileUpload('change', event)}
      />
      <p
        className={
          uploadedImages.length > 0
            ? classes.uploadTextTop
            : isMobile
            ? classes.uploadText
            : undefined
        }
        style={{
          width: isMobile ? '100%' : 'max-content',
          fontSize: isMobile ? '12px' : undefined,
          textAlign: isMobile ? 'center' : undefined,
        }}
      >
        {!isMobile && (
          <FormattedMessage id={'events.form.drag.and.drop.prefix'} />
        )}
        <label htmlFor="upload" className={classes.uploadTextDefault}>
          <FormattedMessage id={'events.form.drag.and.drop.link'} />
        </label>
        <FormattedMessage id={'events.form.drag.and.drop.suffix'} />
      </p>
      {!isMobile && (
        <div
          className={classes.headerIcon}
          style={{ display: isLoaded ? 'none' : 'flex' }}
        >
          {!isLoading ? (
            <Icon name={'CheckCircle'} viewBox={'0 0 94 91'} />
          ) : (
            <CircularProgress className={classes.icon} />
          )}
        </div>
      )}
      {uploadedImages.length > 0 &&
        uploadedImages.map((imageUrl: string, index: number) => {
          return (
            <Grid item key={index} xs={12} className={classes.imageGrid}>
              <Cancel
                className={classes.closeButton}
                onClick={() => handleDeleteImage(index)}
              />
              <img
                key={index}
                src={imageUrl}
                alt={`Uploaded Image ${index}`}
                className={classes.uploadedImage}
              />
            </Grid>
          )
        })}
      <Button
        onClick={() => {
          const inputElement = document.getElementById(
            'upload'
          ) as HTMLInputElement
          if (inputElement) {
            sendImage('click', inputElement)
          }
        }}
        className={classes.sendButton}
        disabled={uploadedImages.length === 0}
      >
        <FormattedMessage id={'events.form.button.send'} />
      </Button>

      <p className={classes.footerInfo}>
        <FormattedMessage id={'events.form.drag.and.drop.types'} />
      </p>
    </Grid>
  )
}
export default DragAndDrop
