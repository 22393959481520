import React, { useState } from 'react'
import feedbackformAddFile from '@api/feedbackformAddFile'
import useToggleComp from '@hooks/useToggleComp'

export interface IDragAndDropContext {
  handleFileUpload: (
    type: string,
    event:
      | HTMLInputElement
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLDivElement>,
    currentEmail?: string
  ) => void
  setUploadedImages: React.Dispatch<React.SetStateAction<string[]>>
  uploadedImages: string[]
  clickFiles: File[]
  isLoaded: boolean
  isLoading: boolean
  filePaths: string[]
}

const DragAndDropContext = React.createContext<IDragAndDropContext>({
  handleFileUpload: () => {
    return null
  },
  setUploadedImages: () => {
    return null
  },
  uploadedImages: [],
  clickFiles: [],
  isLoaded: false,
  isLoading: false,
  filePaths: [],
})
export type DragAndDropProps = { children: React.ReactElement }

export function DragAndDropProvider({
  children,
}: DragAndDropProps): React.ReactElement {
  const [uploadedImages, setUploadedImages] = useState<Array<string>>([])
  const [filePaths, setFilePaths] = useState<Array<string>>([])
  const [clickFiles, setClickFiles] = useState<Array<File>>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)
  const { toggleOverlay } = useToggleComp()

  const isDragEvent = (
    event:
      | HTMLInputElement
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLDivElement>
  ): event is React.DragEvent<HTMLDivElement> => {
    return event instanceof DragEvent
  }

  const handleFileUpload = (
    type: string,
    event:
      | HTMLInputElement
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLDivElement>
  ) => {
    let files: File[] = []

    if (type === 'drop' && !isDragEvent(event)) {
      const dragEvent = event as unknown as React.DragEvent<HTMLElement>
      files = Array.from(dragEvent.dataTransfer.files)
      setClickFiles([...clickFiles, ...files])
    } else if (type === 'change' && !isDragEvent(event)) {
      const changeEvent = event as React.ChangeEvent<HTMLInputElement>
      files = Array.from(changeEvent.target.files as FileList)
      setClickFiles([...clickFiles, ...files])
    }

    const newImages = files.map((file) => URL.createObjectURL(file))

    setUploadedImages([...uploadedImages, ...newImages])

    if (type !== 'click') return
    for (let index = 0; index < clickFiles.length; index++) {
      const fileValue = clickFiles[index]
      setIsLoading(true)
      setIsLoaded(false)
      if (fileValue) {
        feedbackformAddFile(fileValue)
          .then((response) => {
            if (response.status === 200) {
              setFilePaths((prevFilePaths) => [
                ...prevFilePaths,
                response.data.filePath,
              ])
              setIsLoading(false)
              setIsLoaded(true)
              setUploadedImages([])
            } else {
              toggleOverlay(true, {
                type: 'addFileError',
                trackingID: 'addFileError',
                width: 'sm',
                onAcceptance: () => toggleOverlay(false),
              })
              setIsLoading(false)
              setIsLoaded(true)
            }
          })
          .catch((error: Error) => {
            console.log('image upload error', error)
            toggleOverlay(true, {
              type: 'addFileError',
              trackingID: 'addFileError',
              width: 'sm',
              onAcceptance: () => toggleOverlay(false),
            })
            setIsLoading(false)
            setIsLoaded(true)
          })
      } else {
        toggleOverlay(true, {
          type: 'addFileError',
          trackingID: 'addFileError',
          width: 'sm',
          onAcceptance: () => toggleOverlay(false),
        })
        setIsLoading(false)
        setIsLoaded(true)
      }
    }
  }

  return (
    <DragAndDropContext.Provider
      value={{
        handleFileUpload,
        setUploadedImages,
        uploadedImages,
        clickFiles,
        isLoaded,
        isLoading,
        filePaths,
      }}
    >
      {children}
    </DragAndDropContext.Provider>
  )
}

export { DragAndDropContext }
