const axios = require('axios')

export default async function feedbackformSaveData(
  values = {}
): Promise<Response> {
  const response = await axios.post(
    process.env.GATSBY_CONTACT_SERVICE_URL + '/api/feedback/save-data',
    values
  )
  return response
}
